import React from 'react';
import { Container } from 'components/common';
import { Wrapper, Flex, Links, Details } from './styles';
import social from './social.json';
import kumofyIcon from 'assets/icons/kumofy_cloud_400.svg';
import { Brand, Logo } from './styles';
export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>

      <Logo src={kumofyIcon} />

        <span>
          © All rights are reserved Kumofy | {new Date().getFullYear()}

        </span>
      </Details>
      {/* <Links>
        {social.map(({ id, name, link, icon }) => (
          <a key={id} href={link} target="_blank" rel="noopener noreferrer" aria-label={`follow me on ${name}`}>
            <img width="24" src={icon} alt={name} />
          </a>
        ))}
      </Links> */}
    </Flex>
  </Wrapper>
);
